import { Injectable } from '@angular/core';

import { ResellerViewModel } from '@features/models/reseller.model';

@Injectable({ providedIn: 'root' })
export class ResellerService {
  private _reseller: ResellerViewModel | null = null;

  get reseller() {
    return this._reseller;
  }

  constructor() {
    const reseller = localStorage.getItem('reseller');
    if (reseller && reseller !== 'undefined') {
      this._reseller = JSON.parse(reseller);
    } else {
      localStorage.removeItem('reseller');
    }
  }

  init(reseller: ResellerViewModel) {
    localStorage.setItem('reseller', JSON.stringify(reseller));
    this._reseller = reseller;
  }
}
